import React from "react"
import styled from "styled-components";
import Responsive from "./responsive"
import StyledA from "./styleda"

const CardIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-color: rgba(51, 51, 51, 0.75);
  transition: background 0.25s;
  border-radius: 50%;
`;

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.mini ? "210px" : "300px"};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 25px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);

  &:hover ${CardIcon} {
    background-color: rgba(47, 128, 237, 0.75);
  }
`;

const CardTitle = styled.h2`
  font-family: 'PT Serif', serif;
  font-size: 30px;
  @media (max-width: 800px) {
    font-size: 30px;
  }
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 0;
`;

const CardSubtitle = styled.h3`
  font-size: 25px;
  @media (max-width: 800px) {
    font-size: 22px;
  }
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
`;

const Card = ({ image, icon, name, title, description, url, mini, imageSize }) => {
  return <Responsive comp={(big) => {
    const inner = <CardWrapper style={{
      backgroundImage: `url('${image}')`,
      backgroundSize: imageSize,
    }} mini={mini || false}>
      {url && <CardIcon>
        {icon}
      </CardIcon>}
    </CardWrapper>;

    const nameH2 = <CardTitle>{name}</CardTitle>

    return <div style={{
      width: big ? (mini ? "calc(33% - 30px)" : "calc(50% - 20px)") : "100%",
      display: "inline-block",
      paddingLeft: mini ? "15px": "10px",
      paddingRight: mini ? "15px": "10px",
      paddingBottom: mini ? "15px": "10px",
      paddingTop: mini ? "15px" : "10px",
      verticalAlign: "top",
    }}>
      {image ? <a href={url}>
        {inner}
      </a> : null}
      {(url && !image) ? <StyledA href={url}>{nameH2}</StyledA> : nameH2}
      <CardSubtitle>{title}</CardSubtitle>
      {description}
    </div>;
  }} />;
};

export default Card;
