import React from "react"

import Nav from "../components/nav"
import Content from "../components/content"
import Card from "../components/card"

import nd209 from "./img/nd209.png"
import progfun from "./img/progfun.png"
import reactive from "./img/reactive.jpg"
import algo from "./img/algo.png"
import ControlMobileRobots from "./img/ControlMobileRobots.jpg"
import bioinf from "./img/bioinf.png"
import bioinf2 from "./img/bioinf2.jpg"
import gameDevelopment from "./img/game-development.jpg"
import cs101 from "./img/cs101.png"
import algebra from "./img/algebra.jpg"
import precalc from "./img/precalc.jpg"
import sciwrite from "./img/sciwrite.jpg"
import { PageTitle, Subtitle } from "../components/titles"
import styled from "styled-components"


const courses = [
  {
    name: "Robotics Nanodegree",
    date: "July 2018",
    place: "Udacity",
    image: nd209,
    grade: null,
    certificate: "https://confirm.udacity.com/TMZXDTDY"
  },
  {
    name: "Functional Programming Principles in Scala",
    date: "September 2012",
    place: "EPFL (Coursera)",
    image: progfun,
    grade: (
      <span>Grade: 100% <strong>with distinction</strong></span>
    ),
    certificate: "/certificates/progfun.pdf"
  },
  {
    name: "Principles of Reactive Programming",
    date: "November 2013",
    place: "EPFL (Coursera)",
    image: reactive,
    grade: (
      <span>Grade: 100% <strong>with distinction</strong></span>
    ),
    certificate: "/certificates/reactive.pdf"
  },
  {
    name: "Algorithms: Design and Analysis, Part 1",
    date: "June 2012",
    place: "Stanford University (Coursera)",
    image: algo,
    grade: (
      <span>Grade: 87.2%</span>
    ),
    certificate: "/certificates/algo.pdf"
  },
  {
    name: "Algorithms: Design and Analysis, Part 2",
    date: "December 2012",
    place: "Stanford University (Coursera)",
    image: algo,
    grade: (
      <span>Grade: 77%</span>
    ),
    certificate: "/certificates/algo2.pdf"
  },
  {
    name: "Control of Mobile Robots",
    date: "July 2016",
    place: "Georgia Institute of Technology (Coursera)",
    image: ControlMobileRobots,
    grade: (
      <span>Grade: 85.6%</span>
    ),
    certificate: "https://www.coursera.org/account/accomplishments/certificate/T8R9TQM9VMZZ"
  },
  {
    name: "Bioinformatics Algorithms (Part 1)",
    date: "November 2013",
    place: "University of California, San Diego (Coursera)",
    image: bioinf,
    grade: (
      <span>Grade: 100%</span>
    ),
    certificate: "/certificates/bioinformatics.pdf"
  },
  {
    name: "Bioinformatics Algorithms (Part 2)",
    date: "July 2015",
    place: "University of California, San Diego (Coursera)",
    image: bioinf2,
    grade: (
      <span>Grade: 93.3%</span>
    ),
    certificate: "/certificates/bioinformatics2.pdf"
  },
  {
    name: "HTML5 Game Development",
    date: "July 2013",
    place: "Udacity",
    image: gameDevelopment,
    grade: (
      <span><strong>With Hightest Distinction</strong></span>
    ),
    certificate: "/certificates/cs255.pdf"
  },
  {
    name: "Computer Science 101",
    date: "April 2012",
    place: "Stanford University (Coursera)",
    image: cs101,
    grade: (
      <span>Grade: 100%</span>
    ),
    certificate: "/certificates/cs101.pdf"
  },
  {
    name: "Intermediate Algebra",
    date: "January 2013",
    place: "University of California, Irvine (Coursera)",
    image: algebra,
    grade: (
      <span>Grade: 97.7% <strong>with distinction</strong></span>
    ),
    certificate: "/certificates/algebra.pdf"
  },
  {
    name: "Pre-Calculus",
    date: "January 2013",
    place: "University of California, Irvine (Coursera)",
    image: precalc,
    grade: (
      <span>Grade: 96.2% <strong>with distinction</strong></span>
    ),
    certificate: "/certificates/precalculus.pdf"
  },
  {
    name: "SciWrite: Writing in the Sciences",
    date: "November 2013",
    place: "Stanford University (Stanford Online)",
    image: sciwrite,
    grade: (
      <span>Grade: 85%</span>
    ),
    certificate: "/certificates/sciences.pdf"
  }
]

const linkIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="75" viewBox="0 0 24 24" width="75" style={{
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  }}>
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/>
  </svg>
);

const EduSubSubtitle = styled.h3`
  font-family: 'Playfair Display', serif;
  font-size: 35px;
  @media (max-width: 800px) {
    font-size: 30px;
  }
  color: #505050;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default class Education extends React.Component {
  render() {
    return (
      <div>
        <Nav current="education"/>
        <Content>
          <PageTitle>I'm always learning</PageTitle>

          <Subtitle>UC Berkeley</Subtitle>

          <EduSubSubtitle>PhD Computer Science</EduSubSubtitle>
          <div style={{ fontSize: 25, fontWeight: 400 }}>Starting Fall 2021</div>

          <EduSubSubtitle>B.S. Electrical Engineering and Computer Science</EduSubSubtitle>

          <div>
            <span style={{ fontSize: 25, fontWeight: 400 }}>EE/CS Upper-Division Coursework:</span>
            <ul style={{marginTop: 5}}>
              <li>CS W182: Designing, Visualizing and Understanding Deep Neural Networks</li>
              <li>EECS 290: Hardware for Machine Learning</li>
              <li>CS 162: Operating Systems and System Programming (top 3.1%)</li>
              <li>CS 194: Special Topics - Parallel Programming</li>
              <li>EECS 151LB: Field Programmable Gate Arrays Laboratory</li>
              <li>EECS 151: Digital Design and Integrated Circuits</li>
              <li>CS 164: Programming Languages and Compilers (top 3.2%)</li>
              <li>CS 189: Introduction to Machine Learning (top 11%)</li>
              <li>CS 184: Foundations of Computer Graphics (top 2.8%)</li>
              <li>CS 170: Efficient Algorithms and Intractable Problems (top 2.6%)</li>
              <li>ME C134 / EE C128: Feedback Control Systems</li>
            </ul>
          </div>

          <div>
            <span style={{ fontSize: 25, fontWeight: 400 }}>South Asian Studies:</span>
            <ul style={{marginTop: 5}}>
              <li>History 100F: India in the Islamic World Before Modernity</li>
              <li>History 11: Introduction to the Civilizations and Cultures of the Indian Subcontinent</li>
              <li>Hindi 100A/100B: Intermediate Hindi</li>
            </ul>
          </div>

          <div style={{ width: "100%", borderBottom: "1px solid black" }}></div>

          <Subtitle>Online Courses</Subtitle>

          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {
              courses.map((course, i) => {
                return <Card
                  mini
                  right={false}
                  icon={linkIcon}
                  image={course.image}
                  name={course.name}
                  title={course.date + " • " + course.place}
                  description={(
                    <div style={{ marginTop: 5 }}>
                      { course.grade }
                    </div>
                  )}
                  url={course.certificate}
                  key={i}
                />;
              })
            }
          </div>
        </Content>
      </div>
    )
  }
}
